import {mapActions, mapGetters, mapState} from 'vuex'

export const authComputed = {
  ...mapState('auth', {
    currentUser: (state) => state.currentUser,
  }),
  ...mapGetters('auth', ['loggedIn']),
}

export const layoutComputed = {
  ...mapState('layout', {
    layoutType: (state) => state.layoutType,
    layoutWidth: (state) => state.layoutWidth,
  }),
}

export const authMethods = mapActions('auth', ['logIn', 'logInSocial', 'logOut', 'resetPassword', 'changePasswordWithToken', 'changeProfileImage'])

export const layoutMethods = mapActions('layout', ['changeLayoutType', 'changeLayoutWidth'])
