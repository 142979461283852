<template>
  <div class="social-login">
    <button @click="socialLogin('microsoft')" class="btn">
      <img src="@/assets/images/ms-symbollockup_signin_light.png" class="img-fluid" alt="microsoft logo"/>
    </button>
  </div>
</template>

<script>
export default {
  name: "social-login",
  data() {
    return {
      routeOnCreate: null
    }
  },
  created() {
    this.routeOnCreate = this.$route.query.redirectFrom;
  },
  methods: {
    socialLogin(service) {
      localStorage.setItem("routeRedirectFromBeforeADLogin", JSON.stringify(this.routeOnCreate));
      window.location.href = `${process.env.VUE_APP_API_URL}/api/login/${service}/user`;
    }
  }
}
</script>

<style lang="scss" scoped>
.social-login {
  display: flex;
  justify-content: center;
}
</style>
