<script>
import { authMethods } from "@/state/helpers";
import socialLogin from "@/components/social-login";
import appConfig from "@/app.config";

/**
 * Login component
 */
export default {
  page: {
    title: "Logowanie",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    socialLogin
  },
  data() {
    return {
      email: "",
      password: "",
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
      tryingToLogInWihMicrosoft: false,
      permissionError: false,
      errorCallBack: this.$route.params.error ? this.$route.params.error : null,
      footerYear: new Date().getFullYear(),
      appVersion: process.env.VUE_APP_APP_VERSION
    };
  },
  methods: {
    ...authMethods,
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.tryingToLogIn = true;
      // Reset the authError if it existed.
      this.authError = null;
      return (
        this.logIn({
          email: this.email,
          password: this.password
        })
          // eslint-disable-next-line no-unused-vars
          .then(token => {
            this.tryingToLogIn = false;
            this.isAuthError = false;
            // Redirect to the originally requested page, or to the home page
            this.$router.push(
              this.$route.query.redirectFrom || { name: "home" }
            );
          })
          .catch(error => {
            this.tryingToLogIn = false;
            if (error instanceof TypeError) {
              this.authError = 'Komunikacja z serwerem nie powiodła się. Sprawdź stan połączenia internetowego oraz z siecią VPN i spróbuj ponownie.'
            } else {
              this.authError = error ? error : "";
            }
            this.isAuthError = true;
          })
      );
    },

    tryToLogInWithMicrosoft() {
      this.tryingToLogInWihMicrosoft = true;
    },

    checkError() {
      if (this.errorCallBack !== null) {
        this.errorCallBack = this.errorCallBack.slice(6)
        console.log(this.errorCallBack);
      }
    },

    decryptMailtoEmail(encoded) {
      var address = atob(encoded);
      window.location.href = "mailto:" + address;
    }

  },
  mounted() {
    this.checkError();
  }
};
</script>

<template>
  <div>
    <div class="accountbg" :style="{'background': 'url(' + require('@/assets/images/bg-login.jpg') + ')', 'background-size': 'cover', 'background-position': 'center'}"
    ></div>
    <div class="wrapper-page account-page-full">
      <div class="card shadow-none">
        <div class="card-block">
          <div class="account-box">
            <div class="card-box shadow-none p-4">
              <div class="p-2">
                <div class="text-center mt-4">
                  <router-link tag="a" to="/">
                    <img src="@/assets/images/si-pay-logo.png" height="50" alt="logo" />
                  </router-link>
                </div>

                <h4 class="font-size-18 mt-5 text-center">Witaj ponownie!</h4>
                <p class="text-muted text-center">Zaloguj się, aby kontynuować pracę.</p>
              <div class="p-3">
                <b-alert
                  v-model="isAuthError"
                  variant="danger"
                  class="mt-3"
                  dismissible
                >{{authError}}</b-alert>

                <b-form @submit.prevent="tryToLogIn" class="form-horizontal mt-4">
                  <b-form-group id="input-group-1" label="E-mail" label-for="input-1">
                    <b-form-input
                      id="input-1"
                      v-model="email"
                      type="email"
                      placeholder="Wpisz adres e-mail"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    id="input-group-2"
                    label="Hasło"
                    label-for="input-2"
                    class="mb-3"
                  >
                    <b-form-input
                      id="input-2"
                      v-model="password"
                      type="password"
                      placeholder="Hasło"
                    ></b-form-input>
                  </b-form-group>

                  <div class="form-group row">
                    <div class="col-sm-6">
                      <router-link tag="a" to="/forgot-password">
                        <i class="mdi mdi-lock"></i> Zapomniałeś hasła?
                      </router-link>
                    </div>
                    <div class="col-sm-6 text-right">
                      <b-button type="submit" variant="primary" class="w-md">Zaloguj się</b-button>
                    </div>
                  </div>
                </b-form>
              </div>

              <social-login/>

              <div v-if="errorCallBack" class="social-login-error">
                <div class="alert alert-danger mb-2">
                  {{ errorCallBack }}
                </div>
              </div>

            </div>
            <!-- end card-body -->
          </div>
          <!-- end card -->
          <div class="mt-2 text-center">
            <p class="mb-0">
            © {{ footerYear }} <img src="@/assets/images/si-pay-logo.png" alt height="15" class="mt-n1" /> SI-PAY <span class="text-muted small">{{ appVersion }}</span>
            <br/>
            <!-- <a href="#" @click="decryptMailtoEmail('aXBwQGltcGVsLnBs')">Pomoc</a> -->
            </p>
          </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>


<style lang="scss" scoped>
.social-login-error {
  display: flex;
  justify-content: center;
}
</style>
